import React, { useEffect, useState } from "react";
import { useMixpanel } from "gatsby-plugin-mixpanel";
import ConfigRenderer from "../composite/ConfigRenderer";
import { readObject } from "../utils/objects";
import { useApplicationContext } from "../state";
import SectionContainer from "../components/SectionContainer";

import { config } from "config";

const DefaultAppTemplate = (props) => {
    const mixpanel = useMixpanel();
    const context = useApplicationContext();
    const stepName = props.pageContext.step;
    const step = config.steps[stepName];
    const indexPage = Object.keys(config.steps)[0];

    // Get Step Fields
    const [{
        isIndexStep,
        inputs,
        nextStep,
    }] = useState(() => ({
        isIndexStep: indexPage === stepName,
        ...readObject(step),
    }));

    useEffect(() => {
        if (isIndexStep) {
            const event = process.env.GATSBY_ACTIVE_ENV === "production"
                ? "AppLoad"
                : "TestAppLoad";
            mixpanel.track(event, {
                appVersion: config.version,
                versionNumber: config.rawVersion,
            });
            if (process.env.GATSBY_ACTIVE_ENV !== "production") {
                console.log(`Mixpanel Track: ${event}`);
            }
        }
    }, [isIndexStep, mixpanel]);

    return (
        <SectionContainer>
            <ConfigRenderer
                stepName={stepName}
                context={context}
                step={step}
                inputs={inputs}
                nextStep={nextStep}
                // onSubmit={}
            />
        </SectionContainer>
    );
};

export default DefaultAppTemplate;